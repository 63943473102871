* {
  box-sizing: border-box;
  line-height: 1;
}
:root {
  --c-text: #1D1F22;
  --c-primary: #5ECE7B;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  margin: 0;
}

h2 {
  font-size: 42px;
  color: var(--c-text);
  line-height: 67px;
  font-weight: 400;
  text-transform: capitalize;
}
.flx {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}
.wrapper {
  max-width: 1200px;
  margin-inline: auto;
  padding: 0 20px;
}
.header > .wrapper {
  display: grid;
  grid-template-columns: 1fr 100px 1fr;
  height: 80px;
}
.main {
  padding-bottom: 100px;
}
.header {
  z-index: 9;
  position: relative;
  background-color: #fff;
}
.header .right {
  display: flex;
  justify-content: flex-end;
}

.flx-rr {
  flex-direction: row-reverse;
}
.header > .wrapper > div {
  display: flex;
  align-items: center;
}

.middle {
  justify-content: center;
}
.logo {
  text-align: center;
}

.left > a, 
.currency_switch {
  position: relative;
}

.currency_switch:hover {
  cursor: pointer;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 103px;
  margin-bottom: 190px;
}
.img_wrap {
  position: relative;
  padding-top: 100%;
  margin-bottom: 24px;
}
.img_wrap img {
  position:absolute;
  top:50%;
  left:50%;
  max-width: 100%;
  max-height: 100%;
  transform: translate(-50%,-50%);
}
.product_wrap {
  padding: 15px;
  background-color: #fff;
  transition: 250ms;
}

.product_wrap:hover {
  filter: drop-shadow(0 4px 35px rgba(168, 172, 176, 0.19));
  cursor: pointer;
}

.symbol {
  display: inline-block;
  padding-right: 10px;
}

.cur_line {
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  padding: 8px 20px
}

.curr_dropdown {
  position: absolute;
  width: 114px;
  background-color: #fff;
  padding: 10px 0;
  left:-20px;
  filter: drop-shadow(0 4px 35px rgba(168, 172, 176, 0.19));
}

.drop_curr {
  font-size: 18px;
  font-weight: 500;
}
.drop_curr:hover {
  cursor: pointer;
}
.curr_symbol {
  display: inline-block;
  padding-right: 10px;
}
.cur_line:hover,
.active {
  background-color: #eee;
}

.none {
  display: none;
}
.block {
  display: block;
}

.hidden {
  opacity: 0;
  margin-top:-20px;
  transform: scale(.95);
}

.g_h2 {
  margin-top: 80px;
  margin-bottom: 87px;
}
.visible {
  opacity: 1;
  transform-origin: top;
  margin-top:0;
  transform: scale(1);
}

.dropdown {
  transition: all 200ms;
  top: 30px
}

.dropdown >
ul >
li {
  cursor: pointer;
}

.drop_curr >
img {
  width: 6px;
  margin-bottom: 3px;
  transition: 250ms;
}
.rotateDOWN {
  transform: rotate(180deg);
}
.rotateUP {
  transform: rotate(0deg);
}

.cat {
  text-transform: uppercase;
  line-height: 78px;
  padding: 0 16px;
  border-bottom: 2px solid transparent;
}

.cat:hover {
  cursor: pointer;
}

.act {
  color: var(--c-primary);
  font-weight: 600;
  border-color: var(--c-primary);
}

.new {animation: fade 300ms ease-in-out;}

@keyframes fade {
 0% {opacity: 0; left: 0px;}
 100% {opacity: 1;left: -20px;}
}

.noStock {
  color:#8D8F9A;

}
.outOfStock {
  position: absolute;
  pointer-events: none;
  z-index: 1;
  inset: 0;
  justify-content: center;
  text-transform: uppercase;
  background: rgba(255, 255, 255, 0.5);
}

.desc > div {
  font-weight: 500;
}

.hover_cart {
  position: absolute;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--c-primary);
  width: 52px;
  height: 52px;
  bottom: -26px;
  right: 15px;
  border-radius: 50%;
}
.product_wrap:hover
.hover_cart {
  visibility: visible;
}
.num {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  height: 20px;
  width: 20px;
  top: -8px;
  right: -13px;
  border-radius: 50%;
  background-color: var(--c-text);
  color: #fff;
  font-family: 'Roboto',sans-serif;
}

.loading_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  padding: 50px
}
.loading {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: transparent;
    border: 5px solid #eee;
    border-top-color: var(--c-primary);
    -webkit-animation: 1s spin linear infinite;
    animation: 1s spin linear infinite;
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.cart_header {
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  color:var(--c-text);
  text-transform: uppercase;
}

.flx-c {
  flex-direction: column;
}

.flx-hc {
  justify-content: center;
}

.grow {
  flex-grow: 1;
}

.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 288px;
  width: 200px;
}
.carousel 
img {
  max-width: 200px;
  max-height: 288px;
}

.c-arr {
  z-index: 1;
  position: absolute;
  user-select: none;
  bottom: 16px;
  right: 16px;
  background-color: rgba(0, 0, 0, 0.73);
  width: 24px;
  height: 24px;
}

.c-arr:hover {
  cursor: pointer;
}

.c-arr img {
  position: absolute;
  margin-top: 50%;
  margin-left: 50%;
  transform: translate(-50%, -50%);
  height: 12px;
}

.arrow-left {
  transform: rotate(-180deg);
  right: 48px;
}

.cart-ctr {
  margin-right: 24px;
  height: 288px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plus,
.minus {
  position: relative;
  width: 45px;
  height: 45px;
  border: 1px solid var(--c-text);
}
.plus:before,
.plus:after,
.minus:before {
  content: '';
  position: absolute;
  height: 15px;
  width: 15px;
  border-color: 1px solid var(--c-text);
}

.minus:before,
.plus:before {
  border-bottom: 1px solid;
  top: 6px
}

.plus::after {
  border-right: 1px solid;
  left: 6px
}

.minus:hover,
.plus:hover {
  cursor: pointer;
  user-select: none;
}

.cart-num {
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
}

.cart_products > div,
.total {
  border-top: 1px solid #E5E5E5;
}

.cart_products > div {
  padding: 24px 0;
}

.lft {
  justify-content: flex-start;
  min-height: 288px;
}

.name {
  font-size: 30px;
  font-weight: 600;
  line-height: 27px;
}

.sub {
  font-size: 30px;
  font-weight: 400;
  line-height: 27px;
  padding-top: 16px;
}

.price {
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  padding-top: 20px;
}

.attr {
  padding-top: 20px;
}

.attr-name {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Roboto Condensed', sans-serif;
}

.color-batch {
  width: 32px;
  height: 32px;
}

.attr ul {
  margin-top: 8px;
  margin-bottom: 16px;
}

.attr ul > li > div {
  margin-right: 8px;
}

.attr-txt {
  width: 63px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--c-text);
  font-family: 'Source Sans Pro', sans-serif;
}

.total > div > 
span:first-child {
  display: inline-block;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  width: 110px;
  text-transform: capitalize;
}
.total > 
div > 
span:last-child {
  display: inline-block;
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
}
.total > div {
  padding-bottom: 8px;
}

.total {
  padding-top: 32px;
}

.order {
  width: 279px;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  background-color: var(--c-primary);
  line-height: 17px;
  padding: 13px 0;
  border: 0;
  margin-top: 16px;
}

.attr ul > 
li:hover {
  cursor: pointer;
  user-select: none;
}
.active-param >
.attr-txt {
  background-color: var(--c-text);
  color:#fff;
}

.active-param >
.color-batch {
  outline:1px solid #5ECE7B;
  outline-offset: 2px;
}

[data-value='White'] > div {
  box-shadow: inset 0 0 0 1px var(--c-text)
}

.cart-dropdown {
  position: absolute;
  z-index: 3;
  top:50px;
  padding: 32px 16px;
  width: 330px;
  max-height: 677px;
  right: -30px;
  overflow: hidden;
  overflow-y: auto;
  background-color:#fff;
}

.overlay {
  position: fixed;
  inset: 0;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(57, 55, 72, 0.22);;
  z-index: 2;
}

.cart-drop-header {
  line-height: 26px;
  font-size: 16px;
  font-weight: 500;
}

.cart-drop-header 
> span:first-child {
  font-weight: 700;
}

.cart-dropdown 
.cart_products > div {
  border: 0;
  padding: 20px 0;
}

.cart-dropdown 
.cart_products > div:first-child {
  padding-top: 32px;
}

.cart-dropdown 
.cart_products > div:last-child {
  padding-bottom: 32px;
}

.cart-dropdown .name,
.cart-dropdown .sub,
.cart-dropdown .price {
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
  padding-top: 0;
}
.cart-dropdown .sub {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;  
   text-overflow: ellipsis;
}
.cart-dropdown .price {
  font-weight: 500;
  padding-top: 4px;
}

.cart-dropdown .attr-txt {
  min-width: 24px;
  width: unset;
  height: 24px;
  font-weight: 400;
  font-size: 14px;
  padding: 2px;
}
.cart-dropdown .attr {
  padding-top: 8px;
}

.cart-dropdown .attr-name {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  font-family: 'Raleway';
}
.cart-dropdown .plus,
.cart-dropdown .minus {
  width: 24px;
  height: 24px;
}

.cart-dropdown .plus:before, 
.cart-dropdown .plus:after,  
.cart-dropdown .minus:before {
  width: 9px;
  height: 9px;
}

.cart-dropdown .plus:before,
.cart-dropdown .minus:before {
  top:2px;
  left: 7px;
}

.cart-dropdown .plus:after {
  left:2px;
}
.cart-dropdown
.carousel {
  width: 121px;
  height: 190px;
}
.cart-dropdown
.cart-ctr {
  height: 190px;
  margin-right: 8px;
}

.cart-dropdown
.lft {
  min-height: 190px;
  max-width: 128px;
}
.cart-dropdown
.carousel img {
  max-width: 121px;
  max-height: 190px;
}

.cart-dropdown 
.attr ul {
  margin: 8px 0;
}

.cart-dropdown 
.color-batch {
  width: 16px;
  height: 16px;
}

.cart-dropdown 
.cart-num {
  font-size: 16px;
}
.cart:hover {
  cursor: pointer;
}

.header .right > div {
  height: 40px;
}

.header .right > 
div >
div {
  padding-left: 10px;
}
.currency_switch {
  padding-right: 12px;
}

.cart-dropdown 
.total {
  border: 0;
  padding-top: 0;
}
.cart-dropdown
.total > div:first-child {
  padding-bottom: 32px;
}

.cart-dropdown 
.total > div > span {
  font-size: 16px;
  line-height: 25px;
}

.cart-dropdown 
.total > div > span:last-child {
  float: right;
}

.cart-dropdown-footer {
  gap: 12px;
}
.cart-dropdown
.order {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--c-primary);
  margin: 0;
}

.footer-but {
  flex-basis: 50%;
}

.order.view-bag {
  background-color: #fff;
  border-color: var(--c-text);
  color: var(--c-text)
}

.order:hover {
  cursor: pointer;
}

.image-set {
  align-items: flex-start;
}

.image-set img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.thumb {
  width: 80px;
  height: 80px;
  margin-bottom: 35px;
  justify-content: center;
}

.thumb:hover {
  cursor: pointer;
}

.active-image {
  width: 605px;
  height: 513px;
}

.pr-desc {
  width: 292px;
  margin-left: 105px;
}

.pr-wrap {
  align-items: flex-start;
  margin-top: 80px;
}
.pr-wrap .attr {
  padding: 43px 0 20px;
}

.pr-buy {
  padding: 30px 0 40px;
}
.pr-wrap .attr .attr-txt {
  margin-right: 12px;
}
.pr-buy button {
  margin: 0;
  width: 100%;
  font-size: 16px;
}

.pr-footer-desc * {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  font-family: 'Roboto',sans-serif;
}

.thumbnails {
  margin-right: 30px;
  max-height: 513px;
  overflow-y: auto;
  overflow-x: hidden;
}

.product_wrap .desc p,
.cat-price {
  font-size: 18px;
  line-height: 28px;
}